import dynamic from 'next/dynamic'
import CookieBanner from '@/components/template/CookieBanner'
const Header = dynamic(() => import('@/components/template/header'))
const Footer = dynamic(() => import('@/components/template/footer'))

interface LayoutProps {
  cms: any
  children: React.ReactNode
}

export default function DefaultLayout({ cms, children }: LayoutProps) {
  return (
    <>
      <Header cms={cms} />
      <main>{children}</main>
      <Footer cms={cms} />
      <CookieBanner />
    </>
  )
}
