import styles from './CookieBanner.module.scss'
import { Button, Container, Row, Col } from 'react-bootstrap'
import { useContext, useEffect, useState } from 'react'
import { setCookies, hasCookie } from 'cookies-next'
import { PrivacyPolicyPageUrlContext } from '@/app/context'
import { Trans } from 'next-i18next'

const CookieBanner = () => {
  const [visible, setVisible] = useState(false)
  const PrivacyPolicyPageUrl = useContext(PrivacyPolicyPageUrlContext)

  const setCookie = () => {
    setCookies('_gdpr', new Date(), { expires: new Date('2090-01-01'), sameSite: 'strict' })
    setVisible(false)
  }

  useEffect(() => {
    setVisible(!hasCookie('_gdpr'))
  }, [])

  if (!visible) {
    return <></>
  }

  return (
    <div className={`${styles.root} glob-cookie-banner`}>
      <Container fluid>
        <Row className={'justify-content-between align-items-center'}>
          <Col xs={9} sm={10} md={'auto'}>
            <Trans i18nKey={'cookieBannerMessage'}>
              I accept the usage of cookies and the
              <a href={PrivacyPolicyPageUrl} target={'_blank'} rel="noreferrer">
                privacy policy
              </a>
              .
            </Trans>
          </Col>
          <Col xs={'auto'}>
            <Button variant={'outline-transparent'} size={'sm'} onClick={setCookie}>
              OK
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CookieBanner
